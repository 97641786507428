<template>
  <div>
    <div v-if="timeCard && timeCardActivities">

      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="float-left">
          <h4 id="top">Activity Report Week Starting: <small>{{ formattedDate(timeCard.week_start_date, 1) }}</small></h4>
          <h5 class="float-left"><b-badge :variant="determineStatus()">{{ timeCard.status }}</b-badge></h5>
          <h6 class="float-left mt-1 ml-2">Activity Report Owner: <router-link :to="{ name: 'UserShow', params: { id: timeCard.user_id } }">{{ timeCard.user.full_name }}</router-link></h6>
        </div>
        <div class="float-right">
          <button v-if="timeCard.status === 'Submitted' || timeCard.status === 'Resubmitted'" class="btn btn-danger m-2" v-on:click="approveTimeCard('Rejected')">Reject</button>
          <button v-if="timeCard.status === 'Rejected' || timeCard.status === 'Submitted' || timeCard.status === 'Resubmitted'" class="btn btn-success m-2" v-on:click="approveTimeCard('Approved')">Approve</button>
        </div>
      </div>

      <div v-if="timeCard.status === 'Rejected'" class="alert alert-danger rejection-notes text-left" role="alert">
        <h4>Rejection Note(s):</h4>
        <p>{{ timeCard.rejection_note }}</p>
      </div>

      <div class="card">
        <div class="rainbow-background"></div>
        <div class="table-responsive">
          <table class="card-table table">
            <thead>
            <tr>
              <th class="align-middle">Activity</th>
              <th class="align-middle">Region</th>
              <th class="align-middle">Sun</th>
              <th class="align-middle">Mon</th>
              <th class="align-middle">Tue</th>
              <th class="align-middle">Wed</th>
              <th class="align-middle">Thu</th>
              <th class="align-middle">Fri</th>
              <th class="align-middle">Sat</th>
              <th class="align-middle">Week</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="timeCardActivity in timeCardActivities"  :id="timeCardActivity.id">
            <tr style="border-top: double" v-bind:key="timeCardActivity.id">
              <td class="align-middle">
                {{ timeCardActivity.activity.title }}
              </td>
              <td class="align-middle">
                {{ regionName(timeCardActivity.region) }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_one }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_two }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_three }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_four }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_five }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_six }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_seven }}
              </td>
              <td class="align-middle">
                <b class="week-activity-total">{{ calculateWeekTotal(timeCardActivity) }}</b>
              </td>
            </tr>
              <tr v-bind:key="timeCardActivity.id + 1">
                <td align="left" colspan="10">
                  <b>Audiences:</b> {{ printTopics(timeCardActivity) }}
                </td>
              </tr>
              <tr v-bind:key="timeCardActivity.id + 2">
                <td align="left" colspan="10">
                  <b>Trainees:</b> {{ printTrainees(timeCardActivity) }}
                </td>
              </tr>
              <tr v-bind:key="timeCardActivity.id + 3">
                <td align="left" colspan="10">
                  <b>Comment:</b> {{ printComment(timeCardActivity) }}
                </td>
              </tr>
            </template>
            <tr style="border-top: double">
              <td class="align-middle"><b>Total Days</b></td>
              <td />
              <td class="align-middle"><b>{{ sumDay('day_one') }}</b></td>
              <td class="align-middle"><b>{{ sumDay('day_two') }}</b></td>
              <td class="align-middle"><b>{{ sumDay('day_three') }}</b></td>
              <td class="align-middle"><b>{{ sumDay('day_four') }}</b></td>
              <td class="align-middle"><b>{{ sumDay('day_five') }}</b></td>
              <td class="align-middle"><b>{{ sumDay('day_six') }}</b></td>
              <td class="align-middle"><b>{{ sumDay('day_seven') }}</b></td>
              <td class="align-middle"><b>{{ sumDay('day_one') + sumDay('day_two') + sumDay('day_three') + sumDay('day_four') + sumDay('day_five') + sumDay('day_six') + sumDay('day_seven') }} Total Days</b></td>
            </tr>
            <tr>
              <td align="right" colspan="9">
                <button onclick="window.print()" class="btn btn-primary" type="button">Print Report</button>

              </td>
              <td>
                <router-link v-if="timeCard.id" :to="{ name: 'TimeManagementEdit', params: { id: timeCard.id} }" tag="button" class="btn btn-primary" type="button">Manually Edit</router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="timeCard.status === 'Submitted' || timeCard.status === 'Resubmitted'" class="rejection-notes-container">
        <div class="row">
          <div class="col-md-12">
            <h5 class="float-left">Rejection Notes:</h5>
            <textarea type="text" class="form-control rejection-notes" id="rejection_notes" v-model="timeCard.rejection_note"/>
          </div>
        </div>
      </div>

      <!-- Trainee Modal -->
      <trainee-modal :timeCardActivity="currentTimeCardActivity" :visibility="traineeModalVisibility" :isEditing="false" @onChange="close" />

      <!-- Topic Modal -->
      <topic-modal :timeCardActivity="currentTimeCardActivity" :visibility="topicModalVisibility" :isEditing="false" @onChange="close" />

    </div>
    <loading-overlay v-else />
  </div>
</template>

<script>
import { TimeCardService } from '@/common/services/time_card.service';
import { TimeCardActivityService } from '@/common/services/time_card_activity.service';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import TraineeModal from '@/common/components/trainee-modal.vue';
import TopicModal from '@/common/components/topic-modal.vue';

export default {
  name: 'TimeManagementReview',
  mixins: [HelperMethods],
  components: {
    LoadingOverlay,
    TraineeModal,
    TopicModal,
  },
  data() {
    return {
      timeCard: null,
      timeCardActivities: null,
      filters: FilterService.defaultData(),
      weekTotalDays: 0.0,
      currentTimeCardActivity: null,
      traineeModalVisibility: false,
      topicModalVisibility: false,
    };
  },
  created() {
    this.getTimeCard();
  },
  methods: {
    determineStatus() {
      switch (this.timeCard.status) {
        case 'Approved':
          return 'success';
        case 'In Progress':
          return 'primary';
        case 'Submitted':
          return 'warning';
        case 'Rejected':
          return 'danger';
        default:
          return 'primary';
      }
    },
    approveTimeCard(status) {
      if (this.timeCard) {
        let setStatus = false;
        if (status === 'Approved') {
          this.timeCard.rejection_note = null;
          this.timeCard.status = status;
          setStatus = true;
        }
        if (status === 'Rejected') {
          if (!this.timeCard.rejection_note) {
            this.$notify({
              title: '',
              text: 'You must include a Rejection note before Rejecting this time card.',
              type: 'danger',
              width: 350,
            });
          } else {
            setStatus = true;
            this.timeCard.status = status;
          }
        }

        if (setStatus) {
          TimeCardService.update(this.timeCard.id, this.timeCard)
            .then((response) => {
              this.$router.push({ name: 'TimeManagementIndex' }, () => {
                this.$notify({
                  title: 'Updated Time card',
                  text: `Successfully updated status to: ${response.data.status}!`,
                  type: 'success',
                  width: 350,
                });
              });
            });
        }
      }
    },
    getTimeCard() {
      TimeCardService.get(this.$route.params.id)
        .then((response) => {
          this.timeCard = response.data;

          this.filters.time_card_id_eq = this.timeCard.id;
          const params = FilterService.build({ page: 1, per_page: 50 }, this.filters);
          return TimeCardActivityService.query(params);
        })
        .then((response) => {
          this.timeCardActivities = response.data;
          this.updateTotalDays(this.timeCardActivities);
        });
    },
    sumDay(prop) {
      let sum = 0;

      const filteredTimeCards = this.timeCardActivities.filter(timeCardActivity => timeCardActivity[prop]);
      _.forEach(filteredTimeCards, (timeCardActivity) => {
        sum += this.convertTimeStringToValue(timeCardActivity[prop]);
      });

      return sum;
    },
    openTraineeModal(timeCardActivity) {
      this.close();
      this.currentTimeCardActivity = timeCardActivity;
      this.traineeModalVisibility = true;
    },
    openTopicModal(timeCardActivity) {
      this.close();
      this.currentTimeCardActivity = timeCardActivity;
      this.topicModalVisibility = true;
    },
    close() {
      this.topicModalVisibility = false;
      this.traineeModalVisibility = false;
      this.currentTimeCardActivity = null;
    },
    updateTotalDays(timeCardActivities) {
      let runningTotal = 0.0;

      for (let i = 0; i < timeCardActivities.length; i += 1) {
        const days = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];

        for (let j = 0; j < 7; j += 1) {
          const column = 'day_'.concat(days[j]);

          runningTotal += this.convertTimeStringToValue(timeCardActivities[i][column]);
        }
      }

      this.weekTotalDays = runningTotal;
    },
    printTopics(activity) {
      let topics = '';

      for (let i = 0; i < activity.topics.length; i += 1) {
        topics += activity.topics[i].title;
        if (i !== activity.topics.length - 1) {
          topics += ', ';
        }
      }

      if (topics === '') {
        topics = 'No topics';
      }

      return topics;
    },
    printTrainees(activity) {
      let trainees = '';

      for (let i = 0; i < activity.trainees.length; i += 1) {
        trainees += activity.trainees[i].first_name;
        trainees += ' ';
        trainees += activity.trainees[i].last_name;

        if (i !== activity.trainees.length - 1) {
          trainees += ', ';
        }
      }

      if (trainees === '') {
        trainees = 'No trainees';
      }

      return trainees;
    },
    printComment(activity) {
      if (activity.comment === null) { return 'No Comment'; }
      return activity.comment;
    },
  },
};
</script>

<style lang="scss" scoped>
  /*@import "../../styles/views/timecard";*/
  .rejection-notes-container {
    margin: 25px 0;
  }
  .rejection-notes {
    width: 100%;
    display: block;
  }
</style>
